

<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header/>

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page illustration -->
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
        <PageIllustration/>
      </div>

      <section class="relative">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 relative">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Page header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <h1 class="h1 font-red-hat-display mb-4">Contacta con nosotros</h1>
              <p class="text-xl text-gray-600 dark:text-gray-400">Te responderemos lo antes posible</p>
            </div>

            <!-- Contact form -->
            <form class="max-w-xl mx-auto" @submit.prevent="sendEmail">
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="first-name">Nombre
                    <span class="text-red-600">*</span></label>
                  <input id="first-name" type="text" class="form-input w-full" placeholder="¿Cuál es tu nombre?"
                         required/>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="last-name">Apellido
                    <span class="text-red-600">*</span></label>
                  <input id="last-name" type="text" class="form-input w-full" placeholder="¿Cuál es tu apellido?"
                         required/>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full px-3">
                  <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="company">Empresa
                    <span class="text-red-600">*</span></label>
                  <input id="company" type="text" class="form-input w-full"
                         placeholder="¿Cuál es el nombre de tu empresa?" required/>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full px-3">
                  <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="email">Correo
                    electrónico <span class="text-red-600">*</span></label>
                  <input id="email" type="tel" class="form-input w-full" placeholder="Introduce tu correo electrónico"
                         required/>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full px-3">
                  <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="phone">Teléfono
                    <span class="text-red-600"></span></label>
                  <input id="phone" type="tel" class="form-input w-full"
                         placeholder="Si lo prefieres, introduce tu número de teléfono"/>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full px-3">
                  <div class="flex justify-between items-center mb-1">
                    <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium"
                           for="message">Mensaje</label>
                    <span class="text-sm text-gray-500"></span>
                  </div>
                  <textarea id="message" rows="4" class="form-textarea w-full"
                            placeholder="Cuéntanos en qué te podemos ayudar"></textarea>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-5">
                <div class="w-full px-3">
                  <label class="flex items-center">
                    <input type="checkbox" class="form-checkbox" v-model="tos">
                    <span class="ml-2">He leído y acepto la <span class="underline">política de privacidad</span></span>
                  </label>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3">
                  <button class="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center">
                    <span>Enviar</span>
                    <svg class="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12"
                         xmlns="http://www.w3.org/2000/svg">
                      <path class="fill-current"
                            d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"/>
                    </svg>
                  </button>
                </div>
              </div>

              <div v-if=msgSend class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3">
                  <div class="bg-teal-200 border border-teal-700 text-teal-700 px-4 py-3 rounded relative" role="alert">
                    <strong class="font-bold">Hemos redibito tu mensaje. </strong><br>
                    <span class="block sm:inline"> Contactaremos contigo en breve</span>
                  </div>

                </div>
              </div>
            </form>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer/>

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import PageIllustration from './../partials/PageIllustration.vue'
import Footer from './../partials/Footer.vue'
import emailjs from 'emailjs-com';

export default {
  name: 'Contact',
  components: {
    Header,
    PageIllustration,
    Footer,
  },
  data: function () {
    return {
      tos: false,
      msgSend: false
    }
  },
  methods: {
    sendEmail: (e) => {
      this.msgSend = true;
      emailjs.sendForm('service_t1wftaz', 'template_ix9g5ej', e.target, 'user_5zxWMwRscCGFjDFi7HNEG')
          .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
            this.msgSend = true;
          }, (error) => {
            console.log('FAILED...', error);
          });
    }
  }

}
</script>
